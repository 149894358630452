<template>
  <v-row>
    <v-col class="dashboard d-flex flex-wrap justify-center">
      <div>
        <v-card>
          <ul>
            <li>Hulp nodig? Bel 0639043834</li>
          </ul>
  <v-card-title class="text-h4 justify-center">{{client.name}}</v-card-title>
</v-card>
<v-card>
  <v-card-title class="text-h4 justify-center">Openstaand Saldo</v-card-title>
  <v-card-text class="text-h5 text-center">{{toCurrency(debit)}}</v-card-text>
</v-card>
</div>
<div>
<v-card>
  <v-card-title class="text-h4 justify-center">Nog te factureren</v-card-title>
   <v-card-text class="text-h5 text-center">{{toCurrency(openOrdersDebit)}}</v-card-text>
</v-card>
</div>
<div>
  <v-card>
     <v-data-table
      :items="openOrders"
      :headers="openOrdersHeaders"
      :server-items-length="openOrdersLength"
      :options.sync="openOrdersOptions"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 25, 50, 100]
      }"
      item-key="_id"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip label :color="orderStatuses[item.status].color">{{
          orderStatuses[item.status].text
        }}</v-chip>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ toDateString(item.date) }}
      </template>
      <template v-slot:[`item.grossTotal`]="{ item }">
       {{toCurrency(item.grossTotal)}}
    </template>
    </v-data-table>
  </v-card>
</div>
<div>
</div>
</v-col>
  </v-row>
</template>

<script>
import { config } from "../config/config"
import { toCurrency, toDateString } from '../js/functions'
export default {
  name: "myDashboard",
  data() {
    return {
      debit: "",
      openOrdersDebit: 0,
      openOrders: [],
      outstandingInvoices: [],
      openOrdersLength: 0,
      openOrdersHeaders: [
        { text: "Status", value: "status" },
        { text: "Datum", value: "date" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Totaal", value: "grossTotal" }
      ],
      openOrdersOptions: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["orderNumber"],
        sortDesc: [true]
      }
    }
  },
  methods: {
    toCurrency(n){
      return toCurrency(n)
    },
    toDateString(val){
      return toDateString(val)
    },
    async fetchClientAccount() {
     let res = await fetch(
        `${config.api.host}/clientAccount/${this.client.clientNumber}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include"
        }
      )
      let response = await res.json()
      console.log(response)
      this.openOrdersDebit = response[0].value[0].debit
      this.debit = response[1].value[0].debit
      this.openOrders = response[2].value
      this.openOrdersLength = response[2].value.length
      this.outstandingInvoices = response[3].value[0]
    },
    async fetchOpenOrders(){
      let res = await fetch(
        `${config.api.host}/fetchOpenOrders/${this.client.clientNumber}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include"
        }
      )
      let response = await res.json()
      console.log(response)
    }
  },
  watch: {
    openOrdersOptions(newValue, oldValue) {
      let props = ["page", "itemsPerPage", "sortBy", "sortDesc"]
      for (let prop of props) {
        if (oldValue[prop] !== newValue[prop]) return this.fetchOrders()
      }
    }
  },
  computed: {
    client() {
      return this.$store.state.client
    },
    orderStatuses() {
      return this.$store.state.settings.orderStatuses
    }
  },
  mounted() {
    this.fetchClientAccount()
  }
}
</script>

<style scoped>
.dashboard div{
  width: 100%;
  margin-bottom: 5px;
}
</style>